// // Footer.js
// import React from 'react';

// const Footer = () => {
//   return (
//     <footer>
//       <div className="footer-item">
//         <div className='footerAddress'>Address</div>
//         <div className='footerAddressDiv'>
//           Bay Studios <br />
//           31 Moor St<br />
//           Spondon<br />
//           Derby DE21 7EA<br />
//           <br/>
//           07776111968<br />
//           <a style={{textDecoration: 'underline', color: 'white',}} href='mailto:lizzie@lizziefoxhair.com'>lizzie@lizziefoxhair.com</a>
//           <a href='/policies'><button className='policiesButton'>Policies</button></a>
//           </div>
//       </div>
//       <div className="footer-item logo"><img src='/images/3-f73e3abc-1920w.png' alt='lfh logo'/></div>
//       <div className="footer-item" style={{gap: '8px'}}>
//         <div className='footerAddress'>Opening Hours</div>
//         <div className='openingHoursDiv'>
//           <div>Monday</div>
//           <div>Closed</div>
//         </div>
//         <div className='openingHoursDiv'>
//           <div>Tuesday</div>
//           <div>9:30 am - 6:00 pm</div>
//         </div>
//         <div className='openingHoursDiv'>
//           <div>Wednesday</div>
//           <div>9:30 am - 6:00 pm</div>
//         </div>
//         <div className='openingHoursDiv'>
//           <div>Thursday</div>
//           <div>Closed</div>
//         </div>
//         <div className='openingHoursDiv'>
//           <div>Friday</div>
//           <div>9:30 am - 6:00 pm</div>
//         </div>
//         <div className='openingHoursDiv'>
//           <div>Saturday</div>
//           <div>9:30 am - 3:00 pm</div>
//         </div>
//         <div className='openingHoursDiv'>
//           <div>Sunday</div>
//           <div>Closed</div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

// Footer.js
import React from "react";

const Footer = () => {
  return (
    <>
    <footer>
      {/* Address Section */}
      <div className="footer-item">
        <div className="footerAddress">Address</div>
        <div className="footerAddressDiv">
          Bay Studios <br />
          31 Moor St
          <br />
          Spondon
          <br />
          Derby DE21 7EA
          <br />
          <br />
          07776111968
          <br />
          <a
            style={{ textDecoration: "underline", color: "white" }}
            href="mailto:lizzie@lizziefoxhair.com"
          >
            lizzie@lizziefoxhair.com
          </a>
          <a href="/policies">
            <button className="policiesButton">Policies</button>
          </a>
        </div>
      </div>

      {/* Logo Section */}
      <div className="footer-item logo">
        <img src="/images/3-f73e3abc-1920w.png" alt="lfh logo" />
      </div>

      {/* Opening Hours Section */}
      <div className="footer-item" style={{ gap: "8px" }}>
        <div className="footerAddress">Opening Hours</div>
        <div className="openingHoursDiv">
          <div>Monday</div>
          <div>Closed</div>
        </div>
        <div className="openingHoursDiv">
          <div>Tuesday</div>
          <div>8:30 am - 5:30 pm</div>
        </div>
        <div className="openingHoursDiv">
          <div>Wednesday</div>
          <div>8:30 am - late</div>
        </div>
        <div className="openingHoursDiv">
          <div>Thursday</div>
          <div>Closed</div>
        </div>
        <div className="openingHoursDiv">
          <div>Friday</div>
          <div>Closed</div>
        </div>
        <div className="openingHoursDiv">
          <div>Saturday</div>
          <div>8:00 am - 2:00 pm</div>
        </div>
        <div className="openingHoursDiv">
          <div>Sunday</div>
          <div>Closed</div>
        </div>
      </div>
    </footer>
      <div class="copyright-text">
        <p>
          Proudly Created by <a href="https://foxwebservices.com" target="_blank" rel="noreferrer">Fox Web Services</a>
          &copy; <span id="year"></span> | All Rights Reserved
        </p>
      </div>
      </>
  );
};

document.addEventListener("DOMContentLoaded", function () {
  const yearSpan = document.getElementById("year");
  const currentYear = new Date().getFullYear();
  yearSpan.textContent = currentYear;
});

export default Footer;
