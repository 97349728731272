
import React, { useState } from 'react';

const Prices = () => {
  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleCategory = (categoryTitle) => {
    setExpandedCategories((prevExpanded) => ({
      ...prevExpanded,
      [categoryTitle]: !prevExpanded[categoryTitle],
    }));
  };

  const getIconSrc = (categoryTitle) => {
    return expandedCategories[categoryTitle] ? '/images/plus.png' : '/images/minus.png';
  };

  return <div className="priceList">
    <div class="menuCategory">
      <h3
        class="menuCategroyTitle"
        onClick={() => toggleCategory('SPECIALIST COLOURING')}
      >
      SPECIALIST COLOURING
      <img
            src={getIconSrc('SPECIALIST COLOURING')}
            alt={expandedCategories['SPECIALIST COLOURING'] ? 'minus' : 'plus'}
            className="categoryIcon"
          />
      </h3>
      <div
        className={`menuItemsWrapper menuCategory1Cols clearfix ${expandedCategories['SPECIALIST COLOURING'] ? 'expanded' : ''
          }`}
      >
        <div class="categoryTextsWrapper"></div>
        <div class="dmRestaurantMenuCol">
          <div class="menuItemBox first">
            <div class="menuItemLeft">
              <div class="menuItemName">Lived in Hair</div>
              <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">A combination of colouring techniques are used to blend your natural colour, creating an effortless, low maintenance look. Leaving your hair with dimension, brightness and shine.</p>
              </div>
              </div>
            </div>
            <div class="menuItemPrice">210.</div>
            <div></div>
          </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Partial Lived In</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">A combination of colouring techniques are used to blend your natural colour, creating an effortless, low maintenance look. Leaving your hair with dimension, brightness and shine. This is a partial application</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">175 | Short 150</div>
            <div></div>
          </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Face Frame</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Brightening just the hairline to blend and enhance your colour, in combination with a root melt and toner to refresh the rest of your hair.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">130 | Short 115</div>
            <div></div>
          </div>
          <div class="menuItemBox last"> <div class="menuItemLeft"> <div class="menuItemName">Freehand Balayage</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">A free hand balayage lightening ends leaving a lighter sunkissed effect.&nbsp;</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">155 | Short 135</div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="menuCategory">
      <h3
        class="menuCategroyTitle"
        onClick={() => toggleCategory('GREY BLENDING')}
      >
      GREY BLENDING
      <img
            src={getIconSrc('GREY BLENDING')}
            alt={expandedCategories['GREY BLENDING'] ? 'minus' : 'plus'}
            className="categoryIcon"
          />
      </h3>
      <div
        className={`menuItemsWrapper menuCategory1Cols clearfix ${expandedCategories['GREY BLENDING'] ? 'expanded' : ''
          }`}
      >
        <div class="categoryTextsWrapper"></div>
        <div class="dmRestaurantMenuCol">
          <div class="menuItemBox first">
            <div class="menuItemLeft">
              <div class="menuItemName">Transition</div>
              <div class="menuItemDesc"> <div class="richText">
                {/* <p class="rteBlock">This is the first application of the grey blending...</p> */}
              </div>
              </div>
            </div>
            <div class="menuItemPrice">325</div>
            <div></div>
          </div>
          <div class="menuItemBox first">
            <div class="menuItemLeft">
              <div class="menuItemName">Maintenance Full</div>
              <div class="menuItemDesc"> <div class="richText">
                {/* <p class="rteBlock">This is the first application of the grey blending...</p> */}
              </div>
              </div>
            </div>
            <div class="menuItemPrice">195 | Short 170</div>
            <div></div>
          </div>
          <div class="menuItemBox first">
            <div class="menuItemLeft">
              <div class="menuItemName">Maintenance Partial</div>
              <div class="menuItemDesc"> <div class="richText">
                {/* <p class="rteBlock">This is the first application of the grey blending...</p> */}
              </div>
              </div>
            </div>
            <div class="menuItemPrice">150 | Short 130</div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="menuCategory"> <h3
        class="menuCategroyTitle"
        onClick={() => toggleCategory('COLOURING')}
      >
      COLOURING
      <img
            src={getIconSrc('COLOURING')}
            alt={expandedCategories['COLOURING'] ? 'minus' : 'plus'}
            className="categoryIcon"
          />
      </h3>
      <div
        className={`menuItemsWrapper menuCategory1Cols clearfix ${expandedCategories['COLOURING'] ? 'expanded' : ''
          }`}
      > <div class="categoryTextsWrapper"></div>
        <div class="dmRestaurantMenuCol"> <div class="menuItemBox first"> <div class="menuItemLeft"> <div class="menuItemName">Colour Consultation</div>
          <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">A consultation is where we can talk through what you would like and discuss your options. A skin test will be carried out ready for your appointment.&nbsp;</p>
          </div>
          </div>
        </div>
          <div class="menuItemPrice">Free</div>
          <div></div>
        </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Root Coverage</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">A permanent colour application applied to the regrowth. Perfect for grey coverage or clients wanting a longer lasting colour.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">120 | Short 110</div>
            <div></div>
          </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Root Coverage &amp; Refresh Ends</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">A permanent colour regrowth application with colour applied through to the ends, to refresh the tone and add shine.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">140 | Short 130</div>
            <div></div>
          </div>
          <div class="menuItemBox last"> <div class="menuItemLeft"> <div class="menuItemName">Semi Permanent Gloss</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Enhances your natural base colour with either warmer, cooler or darker tones.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">110 | Short 100</div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="menuCategory"> <h3
        class="menuCategroyTitle"
        onClick={() => toggleCategory('HIGHLIGHTS')}
      >
      HIGHLIGHTS
      <img
            src={getIconSrc('HIGHLIGHTS')}
            alt={expandedCategories['HIGHLIGHTS'] ? 'minus' : 'plus'}
            className="categoryIcon"
          />
      </h3>
      <div
        className={`menuItemsWrapper menuCategory1Cols clearfix ${expandedCategories['HIGHLIGHTS'] ? 'expanded' : ''
          }`}
      > <div class="categoryTextsWrapper"></div>
        <div class="dmRestaurantMenuCol"> <div class="menuItemBox first"> <div class="menuItemLeft"> <div class="menuItemName">Full Head</div>
          <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Classic weaves or micro slices, creates delicate strands with blonde or colour from root to tip. Full head application will cover from the nape to crown, parting and sides.</p>
          </div>
          </div>
        </div>
          <div class="menuItemPrice">185 | Short 155</div>
          <div></div>
        </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Partial Lights</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Classic weaves or micro slices, creates delicate strands of blonde or colour from root to tip. Partial lights will cover parting, sides and over the crown.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">155 | Short 135</div>
            <div></div>
          </div>
          <div class="menuItemBox last"> <div class="menuItemLeft"> <div class="menuItemName">Top Up</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Classic weaves or micro slices, creates delicate strands of blonde or colour from root to tip. Top up application either through the parting or around the hairline.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">135 | Short 125</div>
            <div></div>
          </div>
        </div>
      </div>
    </div>

    <div class="menuCategory"> <h3
        class="menuCategroyTitle"
        onClick={() => toggleCategory('CUTS & STYLES')}
      >
      CUTS & STYLES
      <img
            src={getIconSrc('CUTS & STYLES')}
            alt={expandedCategories['CUTS & STYLES'] ? 'minus' : 'plus'}
            className="categoryIcon"
          />
      </h3>
      <div
        className={`menuItemsWrapper menuCategory1Cols clearfix ${expandedCategories['CUTS & STYLES'] ? 'expanded' : ''
          }`}
      > <div class="categoryTextsWrapper"></div>
        <div class="dmRestaurantMenuCol"> <div class="menuItemBox first"> <div class="menuItemLeft"> <div class="menuItemName">Cut &amp; Style</div>
          <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Consultation, cleanse, cut and style.&nbsp;</p>
          </div>
          </div>
        </div>
          <div class="menuItemPrice">60 | Short 50</div>
        </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Wet Cut</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Consultation, cleanse, cut, without drying</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">35.</div>
          </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Dry Cut</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Consultation, maintain style with cutting dry hair</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">28.</div>
          </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Restyle Add On</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Added when more time is needed to change your current haircut.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">10.</div>
          </div>
          <div class="menuItemBox last"> <div class="menuItemLeft"> <div class="menuItemName">Blow Dry</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Consultation, cleanse and style hair&nbsp;</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">35 | Short 29</div>
          </div>
        </div>
      </div>
    </div>

    <div class="menuCategory"> <h3
        class="menuCategroyTitle"
        onClick={() => toggleCategory('GENTS')}
      >
      GENTS
      <img
            src={getIconSrc('GENTS')}
            alt={expandedCategories['GENTS'] ? 'minus' : 'plus'}
            className="categoryIcon"
          />
      </h3>
      <div
        className={`menuItemsWrapper menuCategory1Cols clearfix ${expandedCategories['GENTS'] ? 'expanded' : ''
          }`}
      > <div class="categoryTextsWrapper"></div>
        <div class="dmRestaurantMenuCol"> <div class="menuItemBox first"> <div class="menuItemLeft"> <div class="menuItemName">Gents Cut &amp; Style</div>
          <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Consultation, cleanse, cut and style.</p>
          </div>
          </div>
        </div>
          <div class="menuItemPrice">33.</div>
        </div>
          <div class="menuItemBox last"> <div class="menuItemLeft"> <div class="menuItemName">Hairline tidy</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Cutting to tidy the hairline.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">20.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="menuCategory"> <h3
        class="menuCategroyTitle"
        onClick={() => toggleCategory('EXTRA INFORMATION')}
      >
      EXTRA INFORMATION
      <img
            src={getIconSrc('EXTRA INFORMATION')}
            alt={expandedCategories['EXTRA INFORMATION'] ? 'minus' : 'plus'}
            className="categoryIcon"
          />
      </h3>
      <div
        className={`menuItemsWrapper menuCategory1Cols clearfix ${expandedCategories['EXTRA INFORMATION'] ? 'expanded' : ''
          }`}
      > <div class="categoryTextsWrapper"></div>
        <div class="dmRestaurantMenuCol"> <div class="menuItemBox first"> <div class="menuItemLeft"> <div class="menuItemName">Hair length</div>
          <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Short hair is above collar</p>
          </div>
          </div>
        </div>
          <div class="menuItemPrice"></div>
        </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Colour Packages</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">All colours are inclusive of colour application, metal detox and cut & style</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice"></div>
          </div>
          <div class="menuItemBox last"> <div class="menuItemLeft"> <div class="menuItemName">Metal Detox</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Metal Detox detoxes metals from inside the hair and protects hair by 87% less breakages, also helps achieve a purer colour result and provides the hair with vibrancy, shine, softness and nourishment.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="menuCategory"> <h3
        class="menuCategroyTitle"
        onClick={() => toggleCategory('ADD ONS')}
      >
      ADD ONS
      <img
            src={getIconSrc('ADD ONS')}
            alt={expandedCategories['ADD ONS'] ? 'minus' : 'plus'}
            className="categoryIcon"
          />
      </h3>
      <div
        className={`menuItemsWrapper menuCategory1Cols clearfix ${expandedCategories['ADD ONS'] ? 'expanded' : ''
          }`}
      > <div class="categoryTextsWrapper"></div>
        <div class="dmRestaurantMenuCol"> <div class="menuItemBox first"> <div class="menuItemLeft"> <div class="menuItemName">Olaplex</div>
          <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">OLAPLEX® Bond Building Technology works on a molecular level to repair damaged and broken bonds, it will make your hair feel like new hair.&nbsp;</p>
            <p class="rteBlock">Softer, fuller, stronger and shinier. Add on to any service, additional time 15mins.</p>
          </div>
          </div>
        </div>
          <div class="menuItemPrice">25.</div>
        </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Extra Bowl</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">For very thick or very long hair, extra bowls of colour will be needed. This can vary between 1 to 3 bowls.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">15.</div>
          </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Hairline Coverage</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Root coverage for the hairline, top up in between a full root coverage service.&nbsp;</p>
              <p class="rteBlock">Bookable 3 to 4 weeks.&nbsp;</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">27.</div>
          </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Toner</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">To refresh colour, adds personalised tone.</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">29 | Short 25</div>
          </div>
          <div class="menuItemBox "> <div class="menuItemLeft"> <div class="menuItemName">Root Melt &amp; Tone</div>
            <div class="menuItemDesc"> <div class="richText"> <p class="rteBlock">Root melts blends your natural colour into the toner, for a seamless blend.&nbsp;</p>
            </div>
            </div>
          </div>
            <div class="menuItemPrice">35 | Short 31</div>
          </div>
        </div>
      </div>
    </div>

  </div>;

};

export default Prices;
